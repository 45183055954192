import { Editor } from '@tiptap/react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'reactstrap';
import { BLOCK_LIST } from '../../../../../Partials/Blocky/constants/block-list.constants';
import { ContentTypes } from '../../../../../Partials/Blocky/constants/block.types';
import Global from '../../../../../Partials/Blocky/helpers/global.helpers';
import BlockModelBuilder from '../../../../../Partials/Blocky/models/block-model.builder';
import { OpenModalCallback } from '../../main-components/live-blog-editorial-admin.component';
import { WidgetActionType } from '../collaborative-editor/collaborative-editor';
import { DATA_QA_ATTRIBUTES } from '../collaborative-editor/data-qa.attributes';
import '../../../style/content-blocks-selection.scss';
import { connect } from 'react-redux';
import { LiveBlogTypes } from '../../../helpers/utility.helper';

const AVAILABLE_LIVEBLOG_BLOCK_NAMES = [
	// Content blocks
	ContentTypes.article,
	ContentTypes.gallery,
	ContentTypes.image,
	// ContentTypes.imago, // - not for Live blog MVP
	// ContentTypes.spotlightAudio, // - not for Live blog MVP
	'embed_code',
	ContentTypes.link,
	// ContentTypes.highlight, // - not for Live blog MVP
	ContentTypes.video,
	// ContentTypes.dugoutVideo, // - not for Live blog MVP
	// ContentTypes.youtubeSports, // - not for Live blog MVP
	// ContentTypes.onnetworkVideo, // - not for Live blog MVP
	ContentTypes.banner,
	// Widgets V2
	'footballSingleEvent',
	'widgetFootballOdds',
	'footballStandings',
	'footballPlayerH2H',
	'footballTeamH2H',
	'footballTeamH2HMatch',
	'widgetFootballLineups',
];

let LIVEBLOG_BLOCK_LIST: any[] = [];

interface ContentBlocksSelectionProps {
	openModalCallback: OpenModalCallback;
	subDocumentId?: string;
	editor: Editor | null;
	isLiveNewsType: boolean;
}

const ContentBlocksSelection: FC<ContentBlocksSelectionProps> = ({ openModalCallback, subDocumentId, editor, isLiveNewsType }) => {
	const [t] = useTranslation();
	const [disabled, setDisabled] = useState(false);
	const [shownTooltip, setShownTooltip] = useState(null);

	BLOCK_LIST(false).forEach(
		({ blocks }) =>
			(LIVEBLOG_BLOCK_LIST = [
				...LIVEBLOG_BLOCK_LIST,
				...blocks.filter(({ name }) => !LIVEBLOG_BLOCK_LIST.find((block) => block.name === name)),
			].filter(({ name }) => AVAILABLE_LIVEBLOG_BLOCK_NAMES.includes(name))),
	);

	LIVEBLOG_BLOCK_LIST.sort((a, b) => AVAILABLE_LIVEBLOG_BLOCK_NAMES.indexOf(a.name) - AVAILABLE_LIVEBLOG_BLOCK_NAMES.indexOf(b.name));

	const disableCondition = !!(
		editor &&
		(editor.isActive('table') ||
			editor.isActive('heading', { level: 3 }) ||
			editor.isActive('bulletList') ||
			editor.isActive('orderedList') ||
			editor.isActive('blockquote') ||
			editor.isActive('codeBlock'))
	);

	useEffect(() => {
		setDisabled(disableCondition);
	}, [disableCondition]);

	return (
		<div className='content-blocks-selection-wrapper' data-qa={DATA_QA_ATTRIBUTES.CONTENT_BLOCKS_SELECTION}>
			{LIVEBLOG_BLOCK_LIST.map((block) => {
				const buttonId = `tooltip-${block.name}-${subDocumentId}`;

				return (
					<div key={block.name}>
						<Button
							id={buttonId}
							disabled={disabled}
							caret='true'
							bssize='sm'
							className='mb-1 ml-2 shortcut-btn'
							color='outline-secondary'
							onClick={() => {
								const data = JSON.parse(JSON.stringify(block.default_data));

								data.id = `${Global.makeId(6)}`;
								openModalCallback(new BlockModelBuilder(data).build(), WidgetActionType.ADD, undefined, subDocumentId);
							}}
							onMouseOver={(event) => {
								const button = (event.target as HTMLElement).closest('button');

								if (button) {
									const textContainer = button.querySelector('div');

									if (textContainer && textContainer.offsetWidth < textContainer.scrollWidth) {
										setShownTooltip(block.name);
									}
								}
							}}
							onMouseLeave={() => setShownTooltip(null)}
						>
							<i className={block.icon}></i>
							<div>{t(block.name).replace(/ widget/i, '')}</div>
						</Button>
						<Tooltip placement='top' isOpen={shownTooltip === block.name} target={buttonId} key={buttonId}>
							{t(block.name)}
						</Tooltip>
					</div>
				);
			})}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		isLiveNewsType:
			state.liveBlogEditorial &&
			state.liveBlogEditorial.configuration &&
			state.liveBlogEditorial.configuration.type === LiveBlogTypes.LIVE_NEWS,
	};
}

export default connect(mapStateToProps)(ContentBlocksSelection);
